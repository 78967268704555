@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
  margin: 0;
  font-family: "TTInterfaces", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #cdcbca;
}






  html { margin-left: calc(100vw - 100%); }

  /* scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ddd;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #2a3a3f;
    /* border-radius: 6px; */
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }




h1,h2,h3,h4,h5 {
  text-transform: uppercase;
  letter-spacing: 1.03px;
  line-height: 1.2em;
}

h1 {
  /* background: -webkit-linear-gradient(0deg,#cdcbca, aliceblue,#cdcbca,#2a3a3f); */
  /* background: -webkit-linear-gradient(0deg,#cdcbca, aliceblue,#cdcbca,#000);
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100% !important; */
  color: white;
  /* mix-blend-mode: difference; */
  text-shadow: 0 3px 11px rgb(0 0 0 / 40%);
}

h2 {
  /* background: -webkit-linear-gradient(0deg,#cdcbca, aliceblue,#cdcbca,#2a3a3f); */
  /* background: -webkit-linear-gradient(0deg,#cdcbca, aliceblue,#cdcbca,#000); */
  /* background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100% !important; */
  font-size: 40px;
  color: white;
  mix-blend-mode: difference;
  text-shadow: 0 3px 11px rgb(0 0 0 / 40%);
}

p {
  font-size: 20px;
  line-height: 1.4em;
  text-shadow: 0 3px 11px rgb(0 0 0 / 40%);
}

button {
  background: transparent ;
  display: inline-block;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 1.4em;
  border: 1px solid #fff;
  color: #fff;
  padding: 14px 40px;
  border-radius: 3px;
}
/* 
.row {
  width: 100%;
  padding: 0 40px;
} */



@font-face{
  font-family:TTInterfaces;
  src:url(../public/font.woff2) format("woff2"),url(../public/font.woff) format("woff");
  font-weight:600;
  font-style:normal;
  font-display:block;
}
  @font-face{
  font-family:TTInterfaces;
  src:url(../public/font.woff2) format("woff2"),url(../public/font.woff) format("woff");
  font-weight:400;
  font-style:normal;
  font-display:block;
}
  @font-face{
  font-family:Sansation;
  src:url(../public/SansationBold.woff2) format("woff2"),url(../public/SansationBold.woff) format("woff");
  font-weight:700;
  font-style:normal;
  font-display:block;
}
